<template>
  <v-card flat>
    <v-card-text>
      <template v-if="listOfFields && listOfFields.length > 0">
        <module-render :listOfFields="listOfFields" :fields="fields" ref="formReferences" :moduleName="moduleObj.moduleName" :key="reRender" :isRecordCreation="!moduleObj._id"></module-render>
      </template>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import mixins from './mixin'
export default {
  props: ['moduleInfo', 'formId', 'hasPermission', 'isChildrenOftheModule'],
  mixins: [mixins],
  data () {
    return {
      reRender: 0,
      moduleObj: {
        moduleName: this.moduleInfo.name,
        moduleId: this.moduleInfo._id
      },
      parentModuleObj: {
        moduleName: this.$route.params.name,
        moduleId: this.$route.params.id,
        recordId: this.$route.params.record_id
      },
      fieldsLoading: false,
      listOfFields: [],
      fields: {},
      MODULE_URL: 'moduledata',
      items: [],
      search: '',
      companyArray: [],
      isLoading: false
    }
  },
  components: {
    ModuleRender: () => import('@/components/Modules/ModuleFormRender')
  },
  computed: {
    ...mapGetters(['userDetails'])
  },
  mounted () {
    this.isAction = false
    this.getFieldsForModule()
    this.$root.$on('rerenderComponent', () => {
      this.reRender++
    })
    this.$root.$on('saveFormHandler', () => {
      this.saveUpdateHandler()
    })
  },
  methods: {
    saveUpdateHandler (type) {
      if (this.$refs.formReferences.$refs.validateForm.validate()) {
        this.loading = true
        const model = this.$formatter.cloneVariable(this.fields)
        // const url = this.formId ? `${this.MODULE_URL}/${this.moduleInfo.name}/${this.formId}` : `${this.MODULE_URL}/${this.moduleInfo.name}?relation_module=${this.parentModuleObj.moduleName}&relation_id=${this.parentModuleObj.recordId}`
        let url = ''
        if (this.isChildrenOftheModule) {
          if (this.formId) url = `${this.MODULE_URL}/${this.moduleInfo.name}/${this.formId}`
          else url = `${this.MODULE_URL}/${this.moduleInfo.name}`
        } else {
          if (this.formId) url = `${this.MODULE_URL}/${this.moduleInfo.name}/${this.formId}`
          else url = `${this.MODULE_URL}/${this.moduleInfo.name}?relation_module=${this.parentModuleObj.moduleName}&relation_id=${this.parentModuleObj.recordId}&template_id=${this.templateId || ''}`
        }
        let relations = []
        let permissions = []
        // To check relations
        if (!model._id && model.relations && model.relations.length > 0) {
          relations = model.relations
          model.relations = undefined
        } else {
          relations = model.relations
          model.relations = undefined
        }
        // To check Permission
        if (!model._id && model.relations && model.permissions.length > 0) {
          permissions = model.permissions
          model.permissions = undefined
        } else {
          permissions = model.permissions
          model.permissions = undefined
        }
        const modelJson = {
          data: model,
          relations: relations,
          permissions: permissions,
          parentid: this.isChildrenOftheModule && !this.formId ? this.parentModuleObj.recordId : undefined,
          _id: this.formId
        }
        this.$api.execute(this.formId ? 'put' : 'post', url, modelJson)
          .then((response) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: response.data._id ? 'updatedSuccess' : 'addedSucess' })
            this.$eventBus.$emit('saveUpdate')
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    getSingleRecordHandler () {
      this.getRecordHandler(`${this.MODULE_URL}/${this.moduleInfo.name}/get_by_id/${this.formId}`, '')
    }
  },
  beforeDestroy () {
    this.$root.$off('saveFormHandler')
    this.$eventBus.$off('deleteSuccess')
    this.$root.$off('getAPIValues')
    this.$root.$off('getModuleOptions')
    this.$root.$off('rerenderComponent')
  }
}
</script>
